import * as Ariakit from "@ariakit/react";
import { List, X } from "@phosphor-icons/react";
import logo from "../assets/logo.svg";

export function MobileMenu({
  links,
}: {
  links: {
    label: string;
    href: string;
    className?: string;
    beta?: boolean;
  }[];
}) {
  return (
    <Ariakit.DialogProvider>
      <Ariakit.DialogDisclosure className="btn btn-primary flex items-center gap-2">
        <List />
        Menu
      </Ariakit.DialogDisclosure>
      <Ariakit.Dialog className="fixed inset-0 bg-black w-full h-full z-50">
        <div className="flex items-center justify-between h-[var(--header-height)] px-4">
          <div
            style={{
              width: 175,
            }}
            className="text-white flex-shrink-0 filter invert"
          >
            <img className="m-2" src={logo.src} alt="Replicate logo" />
          </div>
          <Ariakit.DialogDismiss className="text-white">
            <X size={24} weight="bold" />
          </Ariakit.DialogDismiss>
        </div>
        <div className="p-4">
          <nav className="flex flex-col gap-2">
            {links.map((link) => {
              return (
                <a
                  key={link.label}
                  href={link.href}
                  className={`text-white text-2xl group flex items-center gap-2 ${link.className}`}
                >
                  <span className="group-hover:underline">{link.label}</span>
                  {link.beta ? (
                    <span className="border border-white rounded-full px-2 py-0.5 text-xs group-hover:no-underline group-hover:bg-white group-hover:text-black">
                      Beta
                    </span>
                  ) : null}
                </a>
              );
            })}
          </nav>
        </div>
      </Ariakit.Dialog>
    </Ariakit.DialogProvider>
  );
}
